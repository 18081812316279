import { forwardRef, Ref } from 'react';

import cn from '@/lib/cn';

interface HeroSectionWrapperProps {
    children: React.ReactNode;
    className?: string;
    outerWrapperClassName?: string;
}

export const HeroSectionWrapper = forwardRef(function HeroSectionWrapper(
    { children, className, outerWrapperClassName }: HeroSectionWrapperProps,
    ref: Ref<HTMLDivElement>
) {
    return (
        <div
            ref={ref}
            className={cn('p-5 snap-center', outerWrapperClassName)}
        >
            <section
                className={cn(
                    'px-5 pb-12 pt-20 bg-gradient-to-b from-analyst-lavender from-10% to-analyst-white border border-analyst-dark-lavender rounded-lg flex justify-center items-center min-h-[calc(100vh-2.5rem)]',
                    className
                )}
            >
                {children}
            </section>
        </div>
    );
});
