import { gql } from 'graphql-request';
import { GetServerSideProps } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import { ParsedUrlQuery } from 'querystring';

import MaxWidthContainer from '@/components/dom/max-width-container';
import { Heading, Paragraph } from '@/components/dom/text-elements';
import { TeamAvatar } from '@/components/global/avatar';
import { Container, ContainerSize } from '@/components/global/container';
import { useCurrentOrganization } from '@/components/global/current-organization';
import { useGlobalState } from '@/components/global/global-state';
import { HeroSectionWrapper } from '@/components/global/HeroSectionWrapper';
import Icon from '@/components/global/icon';
import ReadMore from '@/components/global/read-more';
import { IndexCard } from '@/components/products/index-card';
import { FormatUtcDate } from '@/helpers/dates';
import cn from '@/lib/cn';
import { SITE_NAME } from '@/lib/constants';
import { BUILD_SERVER_SIDE_PROPS_GQL_CLIENT } from '@/lib/graphql';
import { INDEX_CARD_FRAGMENT, INDEX_CARD_FRAGMENT_NAME, IndexCardType } from '@/queries/index-card';

type UserCardType = {
    id: string;
    name: string;
    avatarUrl: Optional<string>;
};

type OrganizationProps = {
    id: string;
    name: string;
    description: string;
    avatarUrl: Optional<string>;
    aboutUrl: string;
    twitterHandle: string;
    publicIndexes: Array<IndexCardType>;
    members: Array<UserCardType>;
};

const ORGANIZATION_QUERY = gql`
    ${INDEX_CARD_FRAGMENT}
    query getOrganization($id: ID!, $threeMonthsAgo: Date!) {
        organization(id: $id) {
            id
            name
            description
            avatarUrl
            aboutUrl
            twitterHandle
            publicIndexes {
                ...${INDEX_CARD_FRAGMENT_NAME}
            }
            members {
                id
                name
                avatarUrl
            }
        }
    }
`;

const Organization = (props: OrganizationProps) => {
    const { globalState } = useGlobalState();
    const { currentUser } = globalState;
    const { currentOrganizationProfileUrl } = useCurrentOrganization();
    const showEditTeamProfileLink =
        currentUser?.primaryOrganization?.canCurrentUserUpdateOrganizationProfile && currentOrganizationProfileUrl;
    const { name, description, avatarUrl, aboutUrl, twitterHandle, publicIndexes } = props;
    const titleText = `${name} | ${SITE_NAME}`;

    return (
        <div className="pt-16">
            <Head>
                <title>{titleText}</title>
                <meta
                    property="og:image"
                    content="https://www.gothematic.com/favicon/favicon-32x32.png"
                />
            </Head>
            <HeroSectionWrapper className="min-h-0 mb-10 py-5 sm:py-12">
                <MaxWidthContainer className="w-full px-0 sm:px-3 max-w-7xl">
                    <div
                        className={cn('flex flex-col items-start gap-y-6 xs:flex-row mb-4 xs:items-center', {
                            'xs:justify-between': showEditTeamProfileLink,
                            'xs:justify-end': !showEditTeamProfileLink,
                        })}
                    >
                        {showEditTeamProfileLink && (
                            <Link
                                href={currentOrganizationProfileUrl}
                                className="flex items-center no-underline text-thematic-blue
                                            hover:text-thematic-purple-darker visited:text-thematic-blue transition-all font-brand-bold"
                                title="Edit Team Profile"
                            >
                                Edit Team Profile
                                <Icon
                                    type="caret"
                                    rotate={180}
                                    color="blueYves"
                                    size="xs"
                                    className="ml-1"
                                    iconClassName="h-full"
                                />
                            </Link>
                        )}
                    </div>

                    <div className="flex flex-col gap-10 lg:flex-row lg:gap-8">
                        <div
                            className={cn('flex flex-col lg:flex-row gap-8 md:basis-1/2', {
                                'md:basis-full': !description || !showEditTeamProfileLink,
                            })}
                        >
                            <div className="xs:max-h-[200px] h-full xs:max-w-[200px] w-full mx-auto lg:mx-0">
                                <div className="pb-[100%] relative">
                                    <TeamAvatar
                                        url={avatarUrl}
                                        name={name}
                                        className="rounded-2xl overflow-hidden absolute top-0 left-0 right-0 bottom-0 w-full h-full"
                                    />
                                </div>
                            </div>

                            <header className="flex-shrink-0 flex items-end justify-between gap-4 lg:flex-col lg:items-start">
                                <div className="flex flex-col gap-y-4 justify-center">
                                    <Heading
                                        importance={4}
                                        className="text-thematic-blue sm:text-xl md:text-2xl"
                                    >
                                        Team
                                    </Heading>

                                    <Heading
                                        importance={1}
                                        className="text-3xl sm:text-4xl lg:text-5xl"
                                    >
                                        {name}
                                    </Heading>
                                </div>
                                {(aboutUrl || twitterHandle) && (
                                    <div className="flex items-center gap-6 lg:flex-col lg:items-start">
                                        {aboutUrl && (
                                            <Link
                                                className="group no-underline text-thematic-blue
                                            hover:text-thematic-purple-darker
                                            visited:text-thematic-blue font-brand-md relative flex items-center"
                                                href={aboutUrl}
                                                title={aboutUrl}
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <Icon
                                                    type="link"
                                                    color="thematicBlue"
                                                    size="base"
                                                    className="mr-2 flex items-center justify-center"
                                                    iconClassName="w-full fill-none group-hover:text-thematic-purple-darker"
                                                />
                                                Website
                                            </Link>
                                        )}

                                        {twitterHandle && (
                                            <div className="flex items-center gap-x-8">
                                                <Link
                                                    className="group text-analyst-dark-lavender no-underline rounded-full border-2 border-analyst-dark-lavender hover:border-analyst-lavender p-2 w-10 h-10 flex items-center justify-center transition-colors"
                                                    href={`https://twitter.com/${twitterHandle}`}
                                                    title={`${twitterHandle} - X Twitter`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <Icon
                                                        type="xTwitter"
                                                        color="analystDarkLavender"
                                                        iconClassName="fill-none group-hover:text-analyst-lavender transition-colors"
                                                    />
                                                    <span className="sr-only">{twitterHandle} X Twitter page</span>
                                                </Link>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </header>
                        </div>

                        {(description || showEditTeamProfileLink) && (
                            <div className="md:basis-1/2 flex-grow">
                                {description && (
                                    <div>
                                        <Paragraph className="font-brand-bold mb-2 text-xl">About</Paragraph>

                                        <ReadMore
                                            text={description}
                                            lines={3}
                                            className="font-brand-md"
                                            showMoreLessClassName="text-thematic-blue hover:text-thematic-purple-darker visited:text-thematic-blue font-brand-md"
                                        />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </MaxWidthContainer>
            </HeroSectionWrapper>

            <Container
                size={ContainerSize.none}
                className="px-4 xl:px-6"
            >
                <Container
                    size={ContainerSize['7xl']}
                    className="bg-analyst-white py-8 md:py-12 rounded-xl my-8 flex flex-col gap-y-4"
                >
                    <div className="flex items-center gap-2">
                        <Heading importance={4}>Indices Created</Heading>
                        <span className="text-analyst-dark-lavender text-xl font-brand-md">{publicIndexes.length}</span>
                    </div>
                    <div className="mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 grid-flow-row-dense gap-4 p-4">
                        {publicIndexes.map((index) => {
                            return (
                                <IndexCard
                                    key={`index-card-${index.id}`}
                                    index={index}
                                    className="shadow-md rounded-xl bg-white border border-analyst-dark-lavender"
                                />
                            );
                        })}
                    </div>
                </Container>
            </Container>
        </div>
    );
};

// export index page
export default Organization;

// static build code is found below
interface Params extends ParsedUrlQuery {
    id: string;
}

export const getServerSideProps: GetServerSideProps = async (context) => {
    const { id } = context.params as Params;

    // Three months ago
    const performanceStartDate = new Date();
    performanceStartDate.setMonth(performanceStartDate.getMonth() - 3);

    const client = BUILD_SERVER_SIDE_PROPS_GQL_CLIENT(context.req.headers);
    const data: { organization: Optional<OrganizationProps> } = await client.request(ORGANIZATION_QUERY, {
        id: id && id.toUpperCase(),
        threeMonthsAgo: FormatUtcDate(performanceStartDate, 'yyyy-MM-dd'),
    });
    const { organization } = data;

    if (!organization) {
        return { notFound: true };
    }

    return { props: organization };
};
